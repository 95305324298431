import React, {useCallback, useState} from 'react'
import styles from './webPage.module.scss'
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import Icon from "../Profile/components/Photos/Icon";
import CustomPhotoDropzone from "../../components/CustomPhotoDropzone";
import TextField from "../../components/FormElements/TextField";
import Checkbox from "../../components/FormElements/Checkbox";
import {Tooltip} from "../../components/QuestionTooltip";
import {PhoneInputField} from "../../components/PhoneInput";
import ButtonFilled from "../../components/FormElements/ButtonFilled";

const profileIcon = (
  <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 448 512" width="48" height="48"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z"></path>
  </svg>
)

export default function WebPage() {
  const {t, i18n} = useTranslation();
  const dispatch = useDispatch();
  const branch = useSelector(s => s.branchReducer);
  const {info, pending} = useSelector((s) => s.branchReducer);
  const [errorModal, setErrorModal] = useState(false);
  const [file, setFile] = useState(null);
  const [active, setActive] = useState(false);
  const [hideContent, setHideContent] = useState(true);

  const onAddBanner = useCallback((img) => {
    const image = new FormData();
    image.append('banner', img);
    // dispatch(branchActions.addBanner(branchId, image));
  }, [i18n.language])

  const onDeleteBanner = (bannerId) => {
    // dispatch(branchActions.deleteBanner(branchId, bannerId));
  }

  const onAddIcon = useCallback((img) => {
    const image = new FormData();
    image.append('icon', img);
    // dispatch(branchActions.changeIcon(branchId, image));
  }, [i18n.language]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.uploaders}>
          <Icon onAddIcon={onAddIcon} title="photoOfProfile" icon={profileIcon}/>
          <div style={{width: '72.2%', height: '218px'}}>
            <CustomPhotoDropzone
              onAddBanner={onAddBanner}
              pending={branch.pending}
              classnames={styles.dropzone}
              title="bgPicture"
            />
          </div>
        </div>
        {
          !!branch.info && (
            <div className={styles.banners}>
              {
                branch.info.banners.map((b) => (
                  <div className={styles.img} key={b.id}>
                    <button
                      onClick={() => onDeleteBanner(b.id)}
                      className={styles.deletePhotoBtn}
                    >{t('delete')}</button>
                    <img src={b.url}/>
                  </div>
                ))
              }
            </div>
          )
        }

        <TextField
          label="Заголовок"
          // value={bonus_amount}
          // onChange={onChangeBonusAmount}
        />

        <div>
          <p className={styles.fieldTitle}>Описание страницы</p>
          <textarea
            // value={state.message}
            // onChange={onChangeMessage}
            // disabled={!enabled}
            // required
          />
        </div>
      </div>
      <div className={`${styles.wrapper} ${hideContent ? styles.hideContent : ''}`}>
        <div>
          <TextField
            label="Instagram"
            placeholder="nickname"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="Instagram"
            placeholder="nickname"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <PhoneInputField
            label="Whatsapp"
            enableSearch={false}
            disableDropdown={true}
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            onChange={() => {
            }}
          />
        </div>
        <div>
          <PhoneInputField
            label="Whatsapp"
            enableSearch={false}
            disableDropdown={true}
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            onChange={() => {
            }}
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
          <PhoneInputField
            label="Telegram"
            enableSearch={false}
            disableDropdown={true}
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            onChange={() => {
            }}
          />
          <TextField
            placeholder="Имя пользователя"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
          <PhoneInputField
            label="Telegram"
            enableSearch={false}
            disableDropdown={true}
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            onChange={() => {
            }}
          />
          <TextField
            placeholder="Имя пользователя"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="Website"
            placeholder="https://"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="Email"
            placeholder="https://"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <PhoneInputField
            label="Телефон"
            enableSearch={false}
            disableDropdown={true}
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            onChange={() => {
            }}
          />
        </div>
        <div>
          <TextField
            label="Threads"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="Tiktok"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="Facebook"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="VK"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="LinkedIn"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="Behance"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="Ok"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="Reddit"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="Substack"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="Twitter"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="Youtube"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>
        <div>
          <TextField
            label="Youtube"
            postfix={() => <Tooltip text="Нажмите чтобы отображать или скрыть поле"><Checkbox/></Tooltip>}
            // value={bonus_amount}
            // onChange={onChangeBonusAmount}
          />
        </div>

      </div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <ButtonFilled onClick={() => setHideContent((prevState) => !prevState)}>
          {hideContent ? 'Показать остальные поля' : 'Скрыть'}
        </ButtonFilled>
      </div>

      <div className={styles.wrapper}>
        <div style={{height: '218px'}}>
          <CustomPhotoDropzone
            onAddBanner={onAddBanner}
            pending={branch.pending}
            classnames={styles.dropzone}
            title="Галерея"
          />
        </div>
      </div>
    </div>
  )
}