import { Roles } from 'config';

import WhatsApp from "../pages/WhatsApp";
import Security from "../pages/Security";
import Analytics from "../pages/Analytics/Analytics";
import Clients from "../pages/Clients";
import ClientsDatabase from "../pages/ClientsDatabase";
import ClientsDatabaseSendNotifications from "../pages/ClientsDatabaseSendNotifications";
import ClientInfoContainer from "../pages/ClientInfoContainer/ClientInfoContainer";
import ClientEditContainer from "../pages/ClientEditContainer/ClientInfoContainer";
import Profile from "../pages/Profile";
import TransactionsComponent from "../pages/Transactions/TransactionsComponent";
import NotificationsContainer from "../pages/Notifications/NotificationsContainer";
import Reviews from "../pages/Reviews";
import Ads from "../pages/Ads/Ads";
import CrossMarketing from "../pages/CrossMarketing/CrossMarketing";
import WebPage from "../pages/WebPage";
import TransactionsBonusComponent from "../pages/Transactions/TransactionsBonusComponent";

export default [
  {
    component: WhatsApp,
    path: '/whatsapp',
    title: 'WhatsApp',
    exact: false,
    permission: [
      Roles.CASHIER,
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: Security,
    path: '/security',
    title: 'Security',
    exact: false,
    permission: [
      // Roles.CASHIER,
      Roles.ADMINISTRATOR,
      // Roles.EMPLOYER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: Analytics,
    path: '/analytics',
    title: 'Аналитика',
    exact: false,
    permission: [
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: Clients,
    path: '/clients',
    title: 'Клиенты',
    exact: true,
    permission: [
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.CASHIER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: ClientsDatabase,
    path: '/clients-database',
    title: 'База клиентов',
    exact: true,
    permission: [
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.CASHIER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: ClientsDatabaseSendNotifications,
    path: '/clients-database/send-notification',
    title: 'База клиентов - отправка уведомлений',
    exact: true,
    permission: [
      Roles.ANALYTIC,
    ],
  },
  {
    component: ClientInfoContainer,
    path: '/clients/client/:id',
    title: 'Клиент',
    exact: true,
    permission: [
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: ClientEditContainer,
    path: '/clients/client/edit/:id',
    title: 'Клиент',
    exact: true,
    permission: [
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.CASHIER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: Profile,
    path: '/profile',
    title: 'Профиль',
    exact: false,
    permission: [
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: TransactionsComponent,
    path: '/',
    title: 'Транзакции',
    exact: true,
    permission: [
      Roles.CASHIER,
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: TransactionsBonusComponent,
    path: '/bonus-transactions',
    title: 'Бонусные транзакции',
    exact: true,
    permission: [
      Roles.CASHIER,
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: NotificationsContainer,
    path: '/clients/notifications',
    title: 'Уведомления',
    exact: true,
    permission: [
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: Reviews,
    path: '/reviews',
    title: 'Отзывы',
    exact: true,
    permission: [
      Roles.CASHIER,
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: Ads,
    path: '/ads',
    title: 'Реклама',
    exact: false,
    permission: [
      // Roles.CASHIER,
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: CrossMarketing,
    path: '/cross-marketing',
    title: 'Cross-marketing',
    exact: false,
    permission: [
      // Roles.CASHIER,
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.ANALYTIC,
    ],
  },
  {
    component: WebPage,
    path: '/web-page',
    title: 'Web',
    exact: false,
    permission: [
      // Roles.CASHIER,
      Roles.ADMINISTRATOR,
      Roles.EMPLOYER,
      Roles.ANALYTIC,
    ],
  },
]